const generateAnalysisChartData = (reads = [], previews = []) => {
  // Collect all unique dates from both reads and previews
  const allDates = Array.from(
    new Set([...reads.map((r) => r.date), ...previews.map((p) => p.date)])
  );

  // Initialize arrays to hold the aligned counts
  const alignedReads = [];
  const alignedPreviews = [];

  // Populate aligned arrays
  allDates.forEach((date) => {
    const readItem = reads.find((r) => r.date === date);
    const previewItem = previews.find((p) => p.date === date);

    alignedReads.push(readItem ? readItem.count : 0);
    alignedPreviews.push(previewItem ? previewItem.count : 0);
  });

  return {
    series: [
      { name: "Reads", data: alignedReads },
      { name: "Previews", data: alignedPreviews },
    ],
    chartOptions: {
      chart: {
        height: 500,
        type: "bar", // Set the chart type to "bar"
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      xaxis: {
        type: "datetime",
        categories: allDates,
      },
      tooltip: {
        x: {
          format: "MMMM dd, yyyy HH:mm", // More readable date format
        },
      },
      yaxis: {
        decimalsInFloat: 0, // Disable decimals on the y-axis
      },
      colors: ["#008FFB", "#00E396"],
    },
  };
};

export { generateAnalysisChartData };
