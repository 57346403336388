<template>
    <ExpandedCommentCard :comment="comment" v-if="isExpanded" @expandedToggled="toggleExpand()" />

    <div :class="{ 'd-flex': true, 'comment': true, ...$props.class }" v-else>
        <Avatar class="comment-avatar" :src="comment?.user?.profile_picture" :alt="comment?.user?.name" />
        <div class="comment-body w-100">
            <!-- <i @click="toggleExpand()" class="icon-size-fullscreen float-end expand"></i> -->
            <b>{{ comment?.user?.name }}</b><br>
            <p>
                {{ comment.content }}
            </p>

            <div class="comment-reply w-100" v-if="isReplying || isViewingReply">
                <textarea v-model="newComment.content" :required="isReplying"
                    class="form-control form-control-sm comment-textarea" placeholder="Enter a reply" name="content"
                    v-if="isReplying"></textarea>
                <div class="reply-text" v-if="isViewingReply">
                    {{ firstReply.content }}
                </div>
            </div>
            <div class="reply-box">
                <button class="btn btn-sm reply-btn" v-if="hasReply" @click="toggleReply()">
                    {{ isViewingReply ? 'Hide' : 'View' }} Reply
                </button>
                <b class="reply-btn" v-else>
                    <div class="d-flex gap-2" v-if="isReplying">
                        <button class="btn btn-sm " @click="setIsReplying(false)">Cancel</button>
                        <button class="btn btn-sm border-1 send" @click="replyComment()" id="replyComment">Send</button>
                    </div>
                    <div class="btn btn-sm " @click=" setIsReplying(true)" v-else>
                        Reply <i class="icon-action-redo rotate"></i>
                    </div>
                </b>
            </div>
        </div>
    </div>

</template>
<script setup>
import Avatar from '@/components/avatar.vue';
import { computed, reactive, ref, watch } from 'vue';
import ExpandedCommentCard from './expanded-comment-card.vue';
import { useCommentStore } from '@/store/commentStore';
import { checkInputValidity } from '@/utils/validator';

// stores
const commentStore = useCommentStore();

const props = defineProps({
    class: Object,
    devotionId: {
        required: true,
        type: Number,
    },
    comment: {
        required: true,
        type: Object,
        default: {
            content: null,
            user: {}
        }
    }
});

const newComment = reactive({
    content: null,
    parent_comment_id: props.comment.id
});

const isReplying = ref(false);
const isViewingReply = ref(false);
const isExpanded = ref(false);
const hasReply = ref(props.comment.replies.length > 0);

const firstReply = ref(props.comment.replies[0] || {});
// watch
watch(() => newComment, () => {
    checkInputValidity("replyComment");
}, { deep: true });

function setIsReplying(value) {
    isReplying.value = value;
    if (value) {
        checkInputValidity("replyComment");
    }
}
function toggleExpand() {
    isExpanded.value = !isExpanded.value;
}
function toggleReply() {
    isViewingReply.value = !isViewingReply.value;
}


async function replyComment() {
    await commentStore.addComment(props.devotionId, newComment);
    hasReply.value = true;
    firstReply.value = {
        content: newComment.content
    }
    isReplying.value = false;
}
</script>

<style scoped>
:root {}

.expand {
    cursor: pointer;
}

.btn {
    color: var(--PRIMARY-COLOR) !important;
}

.btn.send {
    color: #fff !important;
    background: var(--PRIMARY-COLOR) !important;
}

.reply-box {
    display: flex;
    justify-content: flex-end;
}

.reply-btn {
    width: fit-content;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2px;
    margin-top: 5px;
}

.reply-btn .rotate {
    rotate: 180deg;
    display: inline-block;
}

.comment {
    margin: 15px 0;
    gap: 5px;
    --COMMENT-BG: #fff1ee;
}

.comment-avatar {
    height: 30px;
    width: 30px;
    font-size: 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comment-body {
    position: relative;
    background: var(--COMMENT-BG);
    color: var(--PRIMARY-COLOR);
    line-height: 165%;
    border-radius: 8px;
    padding: 10px;
}

.comment-body p {
    padding-top: 10px;
    margin-bottom: 0;
}

.comment-body::before {
    /* content: '';
    background: red;
    border: 0 solid black;
    border-top: 15px solid;
    border-bottom: 5px solid; */
}

.comment-reply {
    margin-top: 10px;
}

.comment-textarea {
    resize: vertical;
}

.reply-text {
    border-top: 1px solid var(--PRIMARY-COLOR);
    background: #fff;
    border-radius: 0 0 6px 6px;
    padding: 6px;
}
</style>