<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>{{ pageTitle }} for {{ dateText }}</h4>
            <h6>Manage {{ pageTitle }}</h6>
          </div>
        </div>
        <ul class="table-top-head">

          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn d-flex gap-1">
          <a
            @click="saveDevotion()"
            href="javascript:void(0);"
            class="btn btn-added"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Save</a
          >

          <router-link
            style="color: var(--PRIMARY-COLOR) !important;"
            :to="cancelRoute"
            class="btn btn-added bg-white"
            ><vue-feather type="x" class="me-2"></vue-feather
            >Cancel</router-link
          >
        </div>
      </div>
      <div class="card">
        <div class="card-body pb-0">
          <div class="d-flex">
            <a
              class="btn btn-added my-3 mx-0"
              @click="pasteDevotion()"
              v-if="devotionStore.copiedDevotion"
            >
              <vue-feather type="clipboard" class="me-2"></vue-feather>
              Paste Copied Devotion
            </a>
          </div>

          <div class="d-mg-flex d-lg-flex justify-content-between gap-3">
            <div class="col-md-6 col-lg-6 col-12">
              <BFormFloatingLabel>{{ pageTitle }} Title</BFormFloatingLabel>
              <BFormInput v-model="devotion.title" class="mb-3" />
            </div>
            <div class="col-3">
              <BFormFloatingLabel>{{ pageTitle }} Category</BFormFloatingLabel>
              <vue-select
                :options="categories"
                v-model="devotion.category_id"
                placeholder="Choose Category"
                class=""
              />
            </div>
            <div class="w-100">
              <BFormFloatingLabel>{{ pageTitle }} Post Time</BFormFloatingLabel>
              <input
                type="time"
                class="form-control"
                v-model="devotion.post_time"
              />
            </div>
          </div>

          <div class="d-flex my-2">
            <a-image
              :width="0"
              :style="{ display: 'none' }"
              :preview="{
                visible,
                onVisibleChange: setVisible,
              }"
              :src="imagePreviewUrl"
            />

            <div
              v-if="imagePreviewUrl"
              class="image-container col-12 rounded"
              @mouseover="showOverlay = true"
              @mouseleave="showOverlay = false"
            >
              <img :src="imagePreviewUrl" alt="Preview" />
              <div v-if="showOverlay" class="overlay">
                <button @click="deleteImage" class="delete-btn">
                  <vue-feather type="trash-2"></vue-feather>
                </button>
                <button @click="setVisible(true)" class="zoom-btn">
                  <vue-feather type="zoom-in"></vue-feather>
                </button>
              </div>
            </div>

            <div class="file-upload" v-else>
              <input type="file" @change="previewImage" />

              <span role="img" aria-label="plus" class="anticon anticon-plus"
                ><svg
                  focusable="false"
                  data-icon="plus"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  viewBox="64 64 896 896"
                >
                  <path
                    d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                  ></path>
                  <path
                    d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8z"
                  ></path></svg
                ><!----></span
              >
              <div class="file-upload-text">Upload</div>
            </div>
          </div>
          <Summernote
            :initialContent="devotion.content"
            v-model:content="devotion.content"
            placeholder="Devotional Body"
            id="editor"
            v-if="showNote"
          >
          </Summernote>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Summernote from "@/components/summernote.vue";
import { useDevotionStore } from "@/store/devotionStore";
import { downloadImage, formatDate } from "@/utils";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { toggleCollapse } from "@/utils/components";
import Swal from "sweetalert2";
import { decodeUrlData } from "./utils/data";
import { devotionTypes } from "@/constants/enum";
import { useWordStore } from "@/store/wordStore";
import { RangePicker } from "ant-design-vue";

// html ref
const collapseHeader = ref(null);

const route = useRoute();
const router = useRouter();
// store
const devotionStore = useDevotionStore();
const wordStore = useWordStore();

// data
const cancelRoute = { name: "devotion-list" };
const action = ref(null);
const showNote = computed(
  () => devotion.value.type == devotionTypes.dailyDevotion
);
const categories = computed(() =>
  devotionStore.devotionCategories.map((category) => ({
    id: category.id,
    text: category.name,
  }))
);

const devotion = ref({
  title: "",
  content: "",
  category_id: null,
  date: null,
  type: null,
  thumbnail: null,
  post_time: null,
});

const pageTitle = computed(() => {
  if (devotion.value.type == devotionTypes.dailyDevotion) {
    return "Devotional";
  }
  return devotion.value.type;
});
const imagePreviewUrl = ref("");
const showOverlay = ref(false);

const dateText = computed(() => formatDate(devotion.value.date));

// methods
const visible = ref(false);
const setVisible = (value) => {
  visible.value = value;
};

function saveDevotion() {
  const redirectRouteName =
    devotion.value.type == devotionTypes.dailyDevotion
      ? "devotion-list"
      : "word-of-day";
  switch (action.value) {
    case "add":
      devotionStore.addDevotion(devotion.value).then((response) => {
        if (response) {
          router.push({ name: redirectRouteName });
        }
      });
      break;
    case "edit":
      devotionStore
        .updateDevotion(devotion.value.id, devotion.value)
        .then((response) => {
          if (response) {
            router.push({ name: redirectRouteName });
          }
        });
      break;
  }
}

function previewImage(event) {
  const file = event.target.files[0];
  if (file) {
    devotion.value.thumbnail = file;
    log(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      imagePreviewUrl.value = reader.result;
    };
  }
}
function deleteImage() {
  imagePreviewUrl.value = "";
  devotion.value.thumbnail = null;
}
function zoomImage() {
  // Handle zooming functionality here
}

/**
 * Load Devotional or Word Of Day Details
 * @param currentDevotion {Object} Devotional or Word Of Day Details
 */
function loadData(currentDevotion, skipDate = false) {
  devotion.value.id = currentDevotion.id;
  devotion.value.title = currentDevotion.title;
  devotion.value.content = currentDevotion.content;
  devotion.value.category_id = currentDevotion.category.id;
  imagePreviewUrl.value = currentDevotion.thumbnail;
  if (!skipDate) {
    devotion.value.post_time = currentDevotion.post_time;
    devotion.value.date = currentDevotion.date;
  }
}

/**
 * Load the Word of day
 * @param date {Date}
 */
async function loadWord(date, dateText) {
  /** @var {Array} */
  const words = wordStore.loadWordByMonth(date.getFullYear(), date.getMonth());
  let wordOfDay = null;
  if (words?.length > 0) {
    wordOfDay = words.find((word) => word.date == dateText).devotion; // find devotion with date
  } else {
    wordOfDay = await devotionStore.fetchDevotionByDate(
      dateText,
      devotionTypes.word
    ); // load from server
  }
  return wordOfDay;
}

/**
 * load the Devotional
 * @param date {Date}
 */
async function loadDevotion(date, dateText) {
  // load the devotion
  /** @var {Array} */
  const devotions = devotionStore.loadDevotionsByMonth(
    date.getFullYear(),
    date.getMonth()
  );
  let currentDevotion = null;
  if (devotions?.length > 0) {
    currentDevotion = devotions.find((devotion) => devotion.date == dateText)
      .devotion; // find devotion with date
  } else {
    currentDevotion = await devotionStore.fetchDevotionByDate(dateText); // load from server
  }

  return currentDevotion;
}

/**
 * Load the data and method required for the specified action
 */
async function initDevotion() {
  action.value = route.params["action"];
  const decodedData = decodeUrlData(route.params["encodedData"]);

  const dateText = decodedData.date;
  const date = new Date(dateText);
  devotion.value.date = dateText;
  devotion.value.type = decodedData.type;

  switch (action.value) {
    case "edit":
      let data = {};
      if (decodedData.type == devotionTypes.dailyDevotion) {
        data = await loadDevotion(date, dateText);
      } else if (decodedData.type == devotionTypes.word) {
        data = await loadWord(date, dateText);
      }
      loadData(data);
      break;
  }
}

async function pasteDevotion() {
  if (!devotionStore.copiedDevotion) {
    copiedDevotion = await navigator.clipboard.readText();
  } else {
    const copiedDevotion = devotionStore.copiedDevotion;
    loadData(copiedDevotion, true);
    devotion.value.thumbnail = await downloadImage(copiedDevotion.thumbnail);
  }
}
onMounted(async () => {
  await devotionStore.fetchDevotionCategories();
  try {
    await initDevotion();
  } catch (e) {
    log(e);
    Swal.fire("Unable to Parse Token", "", "warning");
    router.back();
  }
});
</script>

<style>
.ant-pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container {
  position: relative;
  height: 150px;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay button {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
}

.overlay button:hover {
  text-decoration: underline;
}

.image-container:hover .overlay {
  opacity: 1;
}

.file-upload {
  width: 102px;
  height: 102px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: center;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  transition: border-color 0.3s;
  position: relative;
  flex-direction: column;
}

.file-upload input[type="file"] {
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>
