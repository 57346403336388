<template>
  <div v-if="devotionExists" class="employee-grid-profile" :style="{
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${thumbnail})`,
    position: 'relative'
  }">

    <div class="profile-head">
      <div class="dep-name">
        <h5 :class="{ 'active': isToday, 'inactive': !isToday }">{{ getDateString(date) }}</h5>
      </div>
      <div class="profile-head-action">
        <div class="dropdown profile-action">
          <a href="javascript:void(0);" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            <vue-feather type="more-vertical" class=""></vue-feather>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" @click="copyDevotion()">
                <vue-feather type="edit" class="info-img"></vue-feather>
                Copy
              </a>
            </li>
            <li>
              <router-link class="dropdown-item" :to="`/devotional/edit/${encodedData}`">
                <vue-feather type="edit" class="info-img"></vue-feather>
                Edit
              </router-link>
            </li>
            <li>
              <a @click="deleteDevotion(devotion)" href="javascript:void(0);"
                class="dropdown-item confirm-text mb-0"><vue-feather type="trash-2"
                  class="info-img"></vue-feather>Delete</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="profile-info department-profile-info" :style="{ cursor: 'pointer' }" @click="viewDevotionDetails()">
      <h4 class="devotion-title">{{ devotion.title }}</h4>
    </div>
    <ul class="team-members position-absolute " style="bottom: 10px">
      <li>Total Like(s): {{ devotion.analysis.likes }}</li>
    </ul>
  </div>
  <div v-else class="employee-grid-profile" :style="{
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
  }">
    <div class="profile-head">
      <div class="dep-name">
        <h5 :class="{ 'active': isToday, 'inactive': !isToday }">{{ getDateString(date) }}</h5>
      </div>
    </div>
    <div class="profile-info department-profile-info devotion-title pointer">
      <router-link :to="`/devotional/add/${encodedData}`">
        <h4>No {{ isWordOfDay ? 'Word' : 'Devotional' }} Added</h4>
        <i><vue-feather type="plus" size="10"></vue-feather> Click to Add</i>
      </router-link>
    </div>
  </div>
</template>
<script setup>
import { dateIsToday } from "@/utils/date";
import { computed, ref } from "vue";
import { encodeUrlData } from "../utils/data";
import { devotionTypes } from "@/constants/enum";
import { useDevotionStore } from "@/store/devotionStore";
import { router } from "@/router";
import { notification } from "ant-design-vue";
// stores
const devotionStore = useDevotionStore();
// Define props
const props = defineProps({
  devotion: { type: Object, default: {} },
  date: String,
  type: String
});

const emit = defineEmits({
  "devotionCopied": () => Object
})
// data
const thumbnail = computed(() => {
  return props.devotion.thumbnail ?? 'https://images.unsplash.com/photo-1490730141103-6cac27aaab94?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
})
const isAdding = ref(false);
const isToday = ref(dateIsToday(props.date));
const devotionExists = computed(() => Object.values(props.devotion || {}).length > 0);

const isWordOfDay = computed(() => props.type == devotionTypes.word);


/** The Date In A Base64 Encode this will be used to identify it for update and add */
const encodedData = computed(() => encodeUrlData(props.type, props.date, props.devotion?.id));

function getDateString(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);
  // Get the day of the month
  const dayOfMonth = date.getDate();

  // Get the day of the week
  const dayOfWeek = date.toLocaleString("en-us", { weekday: "long" });

  // Function to get the suffix for the day of the month
  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Get the suffix for the day of the month
  const daySuffix = getDaySuffix(dayOfMonth);

  // Concatenate the formatted string
  const formattedString = `${dayOfMonth}${daySuffix} - ${dayOfWeek}`;

  return formattedString;
}


function viewDevotionDetails() {
  router.push({
    name: "view-devotion",
    params: {
      encodedData: encodedData.value
    }
  })
}
// METHODS
// Function to handle delete action
async function deleteDevotion(devotion) {
  await devotionStore.deleteDevotion(devotion.id, devotion);
};

function copyDevotion() {
  const devotion = { ...props.devotion };
  devotionStore.copiedDevotion = devotion;
  emit('devotionCopied', devotion);
  notification.success({ message: "Devotion copied successfully" })
};

</script>
<style>
.employee-grid-profile {
  height: 175px;
  /*width: 181.6px; */
  background-size: cover;
  background-color: transparent;
}

.employee-grid-profile * {
  color: #ffffff;
}

.pointer {
  cursor: pointer;
}
</style>
