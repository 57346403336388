<template>
    <div class="expanded">
        <Avatar class="comment-avatar" :src="null" :alt="'A'" />
        <div class="comment-body w-100">
            <i class="icon-size-fullscreen float-end expand" @click="toggleExpand()"></i>
            <b>John Doe</b><br>
            <p>
                our text, Jesus' profound teaching on love challenges us as believers
                to rise above our natural inclinations toward selfishness and
                retaliation and embrace a radical love that reflect
            </p>
        </div>
    </div>
</template>
<script setup>
import Avatar from '@/components/avatar.vue';
import { ref } from 'vue';

const props = defineProps({
    class: Object,
    comment: Object
});

const emit = defineEmits({
    "expandedToggled": null
});

function toggleExpand() {
    emit("expandedToggled");
}
</script>

<style>
.expanded {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #fff;
    left: 0;
    top: 0;
    padding: 15px;
}
</style>