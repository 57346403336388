<template>
  <Modal title="User Subscription" ref="subModal" @opened="initData">
    <div>
      <div class="row">
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>User</label>
            <input v-if="props.userId" :value="subscription.user_name" disabled type="text" class="form-control" />
            <vue-select v-else tabindex="1" class="w-100" id="users" :options="users" required name="user"
              v-model="subscription.user_id" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Subscription Plan</label>
            <vue-select class="w-100" id="plans" :options="plans" required name="subscription_plan"
              v-model="subscription.plan_id" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Created At</label>
            <input type="date" class="w-100 form-control" id="plans" :options="plans" required name="subscription_plan"
              v-model="subscription.created_at" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Expires At</label>
            <input type="date" class="w-100 form-control" id="plans" :options="plans" required name="subscription_plan"
              v-model="subscription.expires_at" />
          </div>
        </div>
      </div>
      <div class="modal-footer-btn">
        <button type="button" class="btn btn-cancel me-2" @click="subModal.closeModal()">Cancel</button>
        <button type="submit" class="btn btn-submit" id="submit" @click="saveSubscription()">Submit</button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps, defineExpose } from 'vue';
import { checkInputValidity } from "@/utils/validator";
import { useSubscriptionStore } from "@/store/subscriptionStore";
import { useUserStore } from '@/store/userStore';
import { usePlanStore } from '@/store/planStore';
import Modal from '@/components/modal/modal.vue';
import { DatePicker, Select } from 'ant-design-vue';
import dayjs from 'dayjs';
import { format2RawDate } from '@/utils/date';

// Props
const props = defineProps({
  userId: String
});

// Refs
const subModal = ref(null);

// Stores
const subscriptionStore = useSubscriptionStore();
const userStore = useUserStore();
const planStore = usePlanStore();

// Computed properties
const users = computed(() => userStore.users.filter(user => !user.subscription.is_active).map(user => ({
  text: user.name,
  id: user.id
})));

const plans = computed(() => planStore.subscriptionPlans.map(plan => ({
  text: plan.name,
  id: plan.id
})));

// Reactive subscription data
const subscription = ref({
  user_id: null,
  plan_id: null
});

// Watchers
watch(() => props.userId, (newUserId) => {
  if (newUserId) {
    const user = subscriptionStore.subscriptions().find(user => user.id == newUserId);
    fillData(user);
  } else {
    resetData();
  }
}, { immediate: true, deep: true });

watch(subscription, () => {
  setTimeout(() => checkInputValidity("submit"), 5);
}, { deep: true });

function addHoursToDate(dateString, hoursToAdd = 1) {
  // Create a Date object from the input date string
  let date = new Date(dateString);

  // Add the specified number of hours
  date.setHours(date.getHours() + hoursToAdd);

  // Return the updated date string
  return date.toISOString();
}

// Methods
function saveSubscription() {
  log(subscription.value.created_at);
  log(subscription.value.expires_at);
  if (props.userId) {
    subscriptionStore.updateSubscription(subscription.value).then(() => subModal.value.closeModal());
  } else {
    subscriptionStore.createSubscription(subscription.value).then(() => subModal.value.closeModal());
  }
}

function resetData() {
  subscription.value = {
    user_id: null,
    plan_id: null
  };
}

function fillData(user) {
  if (user) {
    subscription.value = {
      user_name: user.name,
      user_id: user.id,
      plan_id: user.subscription.plan_id,
      created_at: format2RawDate(user.subscription.created_at),
      expires_at: format2RawDate(user.subscription.expires_at)
    };
  }
}

function initData() {
  if (!props.userId) {
    resetData();
  }
  checkInputValidity("submit");
}

// Expose methods
defineExpose({ openModal: () => subModal.value.openModal() });

</script>

<style scoped></style>
