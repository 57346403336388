<template>
  <slot :filteredData="filtered" name="cardTop"> </slot>
  <div :class="['card', ...extraClass]">
    <div class="card-header" v-if="$slots.header">
      <slot :filteredData="filtered" name="header"> </slot>
    </div>
    <div class="card-body">
      <slot :filteredData="filtered" name="top"> </slot>
      <div v-if="search" class="table-top">
        <div :class="['search-set', ...searchClass]">
          <div class="search-input">
            <div style="position: relative;">
              <input
                type="text"
                placeholder="Search"
                class="dark-input"
                :style="{ width: `${searchWidth || '300'}px` }"
                :title="title"
                v-model="filter.value"
              />
              <span
                style="
                  font-family: inherit;
                  position: absolute;
                  left: 0;
                  top: 100%;
                  letter-spacing: 0.04rem;
                  font-size: 11px;
                  color: #637381;
                  background: #fff;
                "
                >{{ title }}</span
              >
            </div>
            <a href="" class="btn btn-searchset"
              ><i data-feather="search" class="feather-search"></i
            ></a>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-end">
          <slot :filteredData="filtered" name="rightHeader"> </slot>
          <div
            class="w-100 d-flex justify-content-end align-items-center gap-2"
            v-if="filterBy"
          >
            <vue-select
              class="col-3"
              :options="processedfilterBy"
              placeholder="Filter"
              @select="(event) => filterTableBy(event.key, event.value)"
              v-model="filterByValue"
            >
            </vue-select>
            <span v-if="isFiltered" class="clear-btn" @click="clearFilter()"
              >&times;</span
            >
          </div>
        </div>
      </div>
      <div class="table-responsive" :style="{ ...tableStyles }">
        <a-table
          :pagination="pagination"
          :columns="columns"
          :data-source="filtered"
        >
          <template v-slot:bodyCell="{ column, record }">
            <template v-for="(_, name) in $slots">
              <!-- Skip Slot if Name is not bodyCell -->
              <slot
                v-if="name == 'bodyCell'"
                v-bind="{ column, record }"
                :name="name"
              ></slot>
            </template>
          </template>
        </a-table>
        <slot :data="filtered" name="footer"> </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, computed } from "vue";

const emit = defineEmits([
  "fetchData",
  "filter",
  "update:currentPage",
  "update:currentFilter",
]);
const props = defineProps({
  columns: Array,
  filters: Array,
  data: Array,
  searchWidth: String,
  extraClass: { default: [], type: Array },
  searchClass: { default: [], type: Array },
  paginationConfig: Object,
  tableStyles: Object,
  pageData: Object,
  filterBy: {
    default: null,
    type: Array,
  },
});

const isFiltered = ref(false);
const filterByValue = ref(false);
const search = ref(false);
const title = ref(null);
const filter = reactive({ value: null });
const filtered = ref(props.data);
const pagination = reactive({});
const processedfilterBy = computed(() => {
  return props.filterBy.map((_filterBy, index) => ({
    id: index,
    text: _filterBy.title,
    ..._filterBy,
  }));
});

const handlePageChange = (page, pageSize) => {
  emit("update:pageData", { page, pageSize });
  emit("fetchData", { page, pageSize });
};

const filterTable = (_filter) => {
  emit("filter", _filter);
  const filters = props.filters || [];
  const canFilter = _filter === "" || filters.length === 0;
  filtered.value = canFilter
    ? props.data
    : props.data.filter((item) =>
        filters.some((filter) =>
          item[filter].toLowerCase().includes(_filter.toLowerCase())
        )
      );
};
const filterTableBy = (key, value) => {
  const text = processedfilterBy.value.find((filterBy) => filterBy.key == key)
  ?.text;
  emit("update:currentFilterText", text);

  isFiltered.value = true;
  filtered.value = props.data?.filter((user) => user[key] == value);
};
const clearFilter = () => {
  isFiltered.value = false;
  filterByValue.value = null;
  filtered.value = props.data;
  emit("update:currentFilterText", null);

};

const updatePagination = () => {
  pagination.onChange = handlePageChange;
  if (props.paginationConfig) {
    pagination = { ...props.paginationConfig };
  }
};

watch(
  () => props.data,
  (_data) => {
    filtered.value = _data;
  }
);

watch(
  [() => filtered.value, () => props.data],
  () => {
    // add index to the data
    filtered.value.forEach((item, index) => {
      item.index = index + 1;
    });
  },
  { deep: true, immediate: true }
);
watch(
  () => filter.value,
  (_new) => {
    filterTable(_new);
  }
);

watch(
  () => props.paginationConfig,
  () => {
    updatePagination();
  },
  { deep: true }
);

onMounted(() => {
  updatePagination();
  filtered.value = props.data;
  if (props.filters) {
    search.value = true;
    title.value =
      "Search by " +
      props.filters.flatMap((_filter) => {
        let filterName = " " + _filter.replace("_", " ");
        return filterName;
      });
  } else {
    title.value = "Search Not Supported";
    search.value = false;
  }
});
</script>
