import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import Swal from "sweetalert2";

export const usePlanStore = defineStore("plan", {
  state: () => {
    return {
      subscriptionPlans: [],
    };
  },
  actions: {
    async deleteSubscriptionPlans(id) {
      try {
        const response = await api.delete(`${apiUrls.plans}/${id}`);
        await this.fetchSubscriptionPlans();
      } catch (error) {}
    },
    async fetchSubscriptionPlans() {
      try {
        const response = await api.get(apiUrls.plans);
        this.subscriptionPlans = response.data.data;
      } catch (error) {}
    },
    async addSubscriptionPlans(plan = { name, price, months }) {
      try {
        const response = await api.post(apiUrls.plans, plan);
        await this.fetchSubscriptionPlans();
        Swal.fire("Subscription Plan Added Successfully", "", "success");
      } catch (error) {}
    },
    async updateSubscriptionPlans(id, plan = { name, price, months }) {
      try {
        const response = await api.patch(`${apiUrls.plans}/${id}`, plan);
        await this.fetchSubscriptionPlans();
        Swal.fire("Subscription Plan Updated Successfully", "", "success");
      } catch (error) {}
    },
  },
});
