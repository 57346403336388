import { isDevelopment } from "@/constants";
import axios from "axios";

// Function to get the access token
export function getAccessToken() {
  return localStorage.getItem("extension");
}

/** Determines if the admin is logged in or not */
export function isAuth() {
  return !!getAccessToken();
}

// Function to set the access token
export function setAccessToken(token) {
  localStorage.setItem("extension", token);
}

export function hasPendingRequests() {
  return !!Number.parseInt(localStorage.getItem("PENDING_REQUESTS"));
}

/**
 * Updates pending request count in local storage.
 * @param {Boolean} increase - Whether to increase (true) or decrease (false) the count.
 */
export function updatePendingRequest(increase = true) {
  const pendingRequests =
    parseInt(localStorage.getItem("PENDING_REQUESTS") || 0) +
    (increase ? 1 : -1);
  localStorage.setItem("PENDING_REQUESTS", Math.max(pendingRequests, 0));
  window.dispatchEvent(
    new CustomEvent("pendingRequestChanged", {
      detail: { storage: pendingRequests },
    })
  );
}

export function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month];
}
export function generateNumberList(start, end) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export function date2Timestamp(date) {
  return new Date(date).getTime();
}
export function formatDate(timestamp, showTime = false) {
  return new Date(timestamp).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",

    ...(showTime
      ? {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      : {}),
  });
}

export const formatAmount = (amount, addNairaSign = false) => {
  return (
    (addNairaSign ? "₦" : "") +
    parseFloat(amount || 0).toLocaleString("en-Us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

window.log = (...messages) => {
  if (isDevelopment) {
    messages.map((message) =>
      console.log(
        `%c Development Mode: %c${message}`,
        "background: #222; color: #bada55; padding: 4px; border-radius: 3px 0 0 3px; font-weight: bold;",
        "background: #f72f00; color: white; padding: 4px; border-radius: 0 3px 3px 0; font-weight: bold;"
      )
    );
  }
};

export async function downloadImage(imageUrl) {
  try {
    const response = await axios.get(imageUrl, { responseType: "blob" });
    const image = response.data;
    image.name = "image.png";
    return image;
  } catch (error) {
    console.error("Failed to download image:", error);
  }
}
