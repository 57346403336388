import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

export const useDonationStore = defineStore("donation", {
  state: () => {
    return {
      donations: [],
    };
  },
  actions: {
    async fetchDonations() {
      try {
        const response = await api.get(apiUrls.donations);
        this.donations = response.data.data;
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
  },
});
