import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import Swal from "sweetalert2";
import { devotionTypes } from "@/constants/enum";
import { reloadDevotions } from "@/utils/devotion";
import { notification } from "ant-design-vue";

export const useDevotionStore = defineStore("devotion", {
  state: () => {
    return {
      copiedDevotion: null,
      devotionCategories: [],
      devotions: [],
      monthlyDevotions: {},
    };
  },
  getters: {
    /**
     *
     * @returns {Array}
     */
    loadDevotionsByMonth: (state) => (year, month) => {
      const date = new Date(year, month).getTime();
      return state.monthlyDevotions[date] ?? [];
    },
    findById: (state) => (devotionId) => {
      const allDevotion = Object.values(state.monthlyDevotions).flat();
      return allDevotion.find((data) => data.devotion?.id == devotionId);
    },
  },
  actions: {
    async fetchDevotionCategories() {
      try {
        const response = await api.get(apiUrls.categories);
        this.devotionCategories = response.data.data;
        return true;
      } catch (error) {
        return false;
      }
    },
    async deleteDevotionCategory(id) {
      try {
        const response = await api.delete(`${apiUrls.categories}/${id}`);
        await this.fetchDevotionCategories();
      } catch (error) {}
    },
    async addDevotionCategory(category = { name, description }) {
      try {
        const response = await api.post(apiUrls.categories, category);
        await this.fetchDevotionCategories();
        notification.success({
          message: "Devotion Category Added Successfully",
        });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async updateDevotionCategory(id, category = { name, description }) {
      try {
        const response = await api.patch(
          `${apiUrls.categories}/${id}`,
          category
        );
        await this.fetchDevotionCategories();
        notification.success({
          message: "Devotion Category Updated Successfully",
        });
      } catch (error) {}
    },
    async addDevotion(
      devotion = { type, title, content, category_id, date, thumbnail }
    ) {
      try {
        await api.post(apiUrls.devotion, devotion, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await reloadDevotions(devotion.type, devotion.date);
        notification.success({ message: `${devotion.type} Added` });
        return true;
      } catch (error) {
        return false;
      }
    },
    async updateDevotion(
      devotionId,
      devotion = { title, content, category_id, date }
    ) {
      try {
        await api.post(
          `${apiUrls.devotion}/${devotionId}`,
          { _method: "PATCH", ...devotion },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        await reloadDevotions(devotion.type, devotion.date);
        notification.success({ message: "Devotion Updated successfully" });
        return true;
      } catch (error) {
        return false;
      }
    },
    async fetchDevotions() {
      try {
        const response = await api.get(apiUrls.devotion, {
          params: { onlyRecent: true },
        });
        this.devotions = response.data.data;
        return true;
      } catch (error) {
        return false;
      }
    },
    async deleteDevotion(id, devotion = { type, date }) {
      try {
        const response = await api.delete(`${apiUrls.devotion}/${id}`);
        await reloadDevotions(devotion.type, devotion.date);
        return true;
      } catch (error) {
        return false;
      }
    },
    async fetchDevotionByMonth({ year, month }) {
      try {
        const params = {
          year,
          month: Number.parseInt(month) + 1,
        };
        const response = await api.get(apiUrls.devotion, {
          params: params,
        });

        // note: Timestamp of the Year and Month is used to Identify the devotion of a month from the
        const timestamp = new Date(year, month).getTime();
        this.monthlyDevotions[timestamp] = response.data.data;
        return true;
      } catch (error) {
        return false;
      }
    },
    async fetchDevotionByDate(date, type = devotionTypes.dailyDevotion) {
      try {
        const response = await api.get(`${apiUrls.devotion}/${date}`, {
          params: {
            type,
          },
        });
        return response.data.data;
      } catch (error) {
        log(error);
        return false;
      }
    },
    // async fetchDevotionCategories(){
    //   try {
    //     const response = await api.get(apiUrls.categories, {
    //       params: { onlyRecent: true },
    //     });
    //     this.devotionCategories = response.data.data;
    //     return true;
    //   } catch (error) {
    //     return false;
    //   }
    // }
  },
});
