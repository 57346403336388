import { createRouter, createWebHistory } from "vue-router";
import SignIn from "@/views/pages/sign-in.vue";

import { isAuth } from "@/utils";
import AdminDashboard from "@/views/pages/dashboard/admin-dashboard.vue";
import UsersList from "@/views/pages/users/users-list.vue";
import DevotionPage from "@/views/pages/devotion/devotion-page.vue";
import WordOfDay from "@/views/pages/devotion/word-of-day.vue";
import AddEditDevotion from "@/views/pages/devotion/add-edit-devotion.vue";
import ViewCategories from "@/views/pages/devotion/devotion-categories-page";
import DevotionInfo from "@/views/pages/devotion/devotion-info.vue";
import PlanPage from "@/views/pages/plans/plan-page.vue";
import SubscriptionPage from "@/views/pages/subscriptions/subscription-page.vue";
import Error404 from "@/views/pages/error/error-404.vue";
import UpdatePage from "@/views/pages/updates/update-page.vue";
import Donations from "@/views/pages/donation/donations.vue";
const routes = [
  {
    path: "/",
    name: "sign-in",

    meta: {
      requiresAuth: false,
      permissionsRequired: [],
    },
    component: SignIn,
  },
  {
    path: "/dashboard",
    name: "dashboard",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: AdminDashboard,
  },
  {
    path: "/devotional",
    name: "devotion",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "",
        name: "devotion-list",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: DevotionPage,
      },
      {
        path: "view/:encodedData",
        name: "view-devotion",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: DevotionInfo,
      },
      {
        path: ":action/:encodedData",
        name: "add-edit-devotion",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: AddEditDevotion,
      },
      {
        path: "categories",
        name: "view-categories",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: ViewCategories,
      },
      {
        path: "word-of-day",
        name: "word-of-day",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: WordOfDay,
      },
    ],
  },
  {
    path: "/users",
    name: "users",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    children: [
      {
        path: "",
        name: "users",

        meta: {
          requiresAuth: true,
          permissionsRequired: [],
        },
        component: UsersList,
      },
    ],
  },
  {
    path: "/plans",
    name: "plans",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: PlanPage,
  },
  {
    path: "/subscriptions",
    name: "subscriptions",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: SubscriptionPage,
  },
  {
    path: "/donations",
    name: "donations",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: Donations,
  },
  {
    path: "/update",
    name: "updates",

    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
    component: UpdatePage,
  },
  {
    path: "/:catchAll(.*)",
    component: Error404,
    meta: {
      requiresAuth: true,
      permissionsRequired: [],
    },
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

/**
 *
 * @param {import("vue-router").RouteLocationNormalized} route
 */
const middleware = (route) => {
  let result = { toLogin: false, canContinue: true };
  if (route.meta.requiresAuth) {
    if (isAuth()) {
      result.canContinue = true;
    } else {
      result.toLogin = true;
      result.canContinue = false;
    }
  }
  return result;
};

router.beforeEach((to, from, next) => {
  const { toLogin, canContinue } = middleware(to);
  if (canContinue) {
    next();
  } else if (toLogin) {
    next("?next=" + to.path);
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
});
