<template>
  <div>
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count">
              <div class="dash-counts">
                <h4>{{ analysis.subscribers.active }}</h4>
                <h5>Active Subscribers</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="user-check"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das1">
              <div class="dash-counts">
                <h4>{{ analysis.subscribers.inactive }}</h4>
                <h5>Inactive Subscribers</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="user-x"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das2">
              <div class="dash-counts">
                <h4>{{ analysis.devotions }}</h4>
                <h5>Total Devotionals</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="book-open"></vue-feather>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12 d-flex">
            <div class="dash-count das3">
              <div class="dash-counts">
                <h4>{{ analysis.downloads }}</h4>
                <h5>Total Downloads</h5>
              </div>
              <div class="dash-imgs">
                <vue-feather type="download"></vue-feather>
              </div>
            </div>
          </div>
        </div>
        <!-- Button trigger modal -->

        <div class="row">
          <div class="col-xl-7 col-sm-12 col-12 d-flex">
            <div class="card flex-fill">
              <div
                class="card-header d-flex justify-content-between align-items-center"
              >
                <h5 class="card-title mb-0">Reads and Previews</h5>
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-end gap-1">
                  <div class="d-flex">
                    <a-range-picker @change="handleAnaysisDateChange" />
                  </div>

                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="fetchDevotionAnalysis"
                  >
                    Fetch Analysis
                  </button>
                </div>
                <div id="sales_charts">
                  <!-- <h5>No Data to Display Currently</h5> -->
                </div>
                <apexchart
                  type="bar"
                  :options="analysisChartData.chartOptions"
                  :series="analysisChartData.series"
                ></apexchart>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-sm-12 col-12 d-flex">
            <div class="card flex-fill default-cover mb-4">
              <div
                class="card-header d-flex justify-content-between align-items-center"
              >
                <h4 class="card-title mb-0">Recent Devotionals</h4>
                <div class="view-all-link">
                  <router-link
                    :to="{ name: 'devotion-list' }"
                    class="view-all d-flex align-items-center"
                  >
                    View All
                    <span class="ps-2 d-flex align-items-center"
                      ><vue-feather
                        type="arrow-right"
                        class="feather-16"
                      ></vue-feather
                    ></span>
                  </router-link>
                </div>
              </div>
              <div class="card-body">
                <a-list
                  class="demo-loadmore-list"
                  :loading="initLoading"
                  item-layout="horizontal"
                  :data-source="recentDevotions"
                >
                  <!-- <template #loadMore>
                    <div
                      v-if="!initLoading && !loading"
                      :style="{
                        textAlign: 'center',
                        marginTop: '12px',
                        height: '32px',
                        lineHeight: '32px',
                      }"
                    >
                      <a-button @click="onLoadMore">loading more</a-button>
                    </div>
                  </template> -->
                  <template #renderItem="{ item }">
                    <a-list-item>
                      <a-skeleton avatar :title="false" :loading="false" active>
                        <a-list-item-meta>
                          <template #title>
                            <router-link
                              :to="devotionDetailsUrl(item)"
                              class="devotion-title mb-1"
                              >{{ item.title }}</router-link
                            >
                            <!-- :href="`devotions/${item.slug}`" -->
                            <div
                              class="ant-list-item-meta-description d-flex align-items-center gap-4 devotion-preview-actions"
                            >
                              <span
                                class="d-block d-flex align-items-center fw-lighter gap-2"
                              >
                                <i class="icon-note"></i>
                                {{ item.analysis.comments.length }} Comment
                              </span>
                              <span
                                class="d-block d-flex align-items-center fw-lighter gap-2"
                              >
                                <i class="icon-like"></i>
                                {{ item.analysis.likes }} Like
                              </span>
                              <!-- <span class="d-block d-flex align-items-center fw-lighter gap-2">
                                <i class="icon-eye"></i>
                                0 View
                              </span> -->
                            </div>
                          </template>
                          <template #avatar>
                            <img
                              class="devotion-preview-image"
                              :src="item.thumbnail"
                            />
                          </template>
                        </a-list-item-meta>
                      </a-skeleton>
                    </a-list-item>
                  </template>
                </a-list>
              </div>
            </div>
          </div>
        </div>

        <filter-table
          :extra-class="['table', 'datanew']"
          :columns="userAnalysis.columns"
          :data="userAnalysis.recentUsers"
          :filters="userAnalysis.filters"
        >
          <template #header>
            <h4>Recent Users</h4>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <div class="userimgname gap-2 align-items-center">
                <a
                  href="javascript:void(0);"
                  class="userslist-img bg-img gap-2"
                >
                  <Avatar
                    :src="record.profile_picture"
                    :alt="record.name"
                    img-class="user-image"
                  />
                </a>
                <div>
                  <a href="javascript:void(0);">{{ record.name }}</a>
                </div>
              </div>
            </template>
            <template v-else-if="column.key === 'status'">
              <div>
                <span :class="getBadgeClass(record.is_active)">{{
                  record.status
                }}</span>
              </div>
            </template>
            <template v-else-if="column.key === 'action'">
              <td class="action-table-data">
                <div class="edit-delete-action">
                  <a class="me-2 p-2 mb-0" href="javascript:void(0);">
                    <vue-feather type="eye" class="action-eye"></vue-feather>
                  </a>
                  <a
                    class="me-2 p-2 mb-0"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                  >
                    <i data-feather="edit" class="feather-edit"></i>
                  </a>
                  <a
                    class="me-2 confirm-text p-2 mb-0"
                    href="javascript:void(0);"
                  >
                    <i data-feather="trash-2" class="feather-trash-2"></i>
                  </a>
                </div>
              </td>
            </template>
          </template>
        </filter-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { generateAnalysisChartData } from "./data";
import Swal from "sweetalert2";
import { useDashboardStore } from "@/store/dashboardStore";
import { mapStores } from "pinia";
import { useDevotionStore } from "@/store/devotionStore";
import { useUserStore } from "@/store/userStore";
import Avatar from "@/components/avatar.vue";
import { usePlanStore } from "@/store/planStore";
import { devotionTypes } from "@/constants/enum";
import { encodeUrlData } from "../devotion/utils/data";

// store
const planStore = usePlanStore();
const dashboardStore = useDashboardStore();
const devotionStore = useDevotionStore();
const userStore = useUserStore();

// data
const recentDevotions = computed(() => devotionStore.devotions.slice(0, 5));
const analysis = ref({
  subscribers: { active: 0, inactive: 0 },
  devotions: 0,
  downloads: 0,
});

const analysisChartDate = ref({ startDate: null, endDate: null });
const analysisChartData = ref(generateAnalysisChartData());
const userAnalysis = ref({
  filters: ["name", "mobile_number"],
  columns: ref([
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Phone",
      dataIndex: "mobile_number",
      sorter: {
        compare: (a, b) => {
          a = a.mobile_number.toLowerCase();
          b = b.mobile_number.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => {
          a = a.email.toLowerCase();
          b = b.email.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: true,
    // },
  ]),
  recentUsers: computed(() => {
    const reversedUsers = [...userStore.users].reverse(); // Make a copy of users array and reverse it
    return reversedUsers.slice(0, 5);
  }),
});
const selectedDates = ref(null);
const showConfirmation = () => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    confirmButtonClass: "btn btn-primary",
    cancelButtonClass: "btn btn-danger ml-1",
    buttonsStyling: false,
  }).then((result) => {
    if (result.value) {
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Your file has been deleted.",
        confirmButtonClass: "btn btn-success",
      });
    }
  });
};
const handleAnaysisDateChange = (date, dateString) => {
  analysisChartDate.value.startDate = dateString[0];
  analysisChartDate.value.endDate = dateString[1];
};
function getBadgeClass(status) {
  return `badge badge-line${status ? "success" : "danger"}`;
}
function devotionDetailsUrl(devotion) {
  return `/devotional/view/${encodeUrlData(
    devotionTypes.dailyDevotion,
    devotion.date,
    devotion.id
  )}`;
}
function fetchDevotionAnalysis() {
  dashboardStore
    .fetchDevotionAnalysis(analysisChartDate.value)
    .then((response) => {
      analysisChartData.value = generateAnalysisChartData(
        response.reads,
        response.previews
      );
    });
}
onMounted(async () => {
  if (recentDevotions.value.length == 0) {
    await devotionStore.fetchDevotions();
  }
  if (userAnalysis.value.recentUsers.length == 0) {
    await userStore.fetchUsers();
  }
  planStore.fetchSubscriptionPlans();
  analysis.value = await dashboardStore.fetchAnalysis();
  fetchDevotionAnalysis();
});
</script>
<style>
.round-avatar {
  width: 50px;
  height: 50px;
  max-width: 100%;
  margin-right: 0;
  border-radius: 20%;
}
</style>
