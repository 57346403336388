import { Envs } from "./enum";

const env = process.env.NODE_ENV;
const isDevelopment = env !== Envs.production;

let apiUrl = "http://127.0.0.1:8000";
switch (env) {
  case Envs.demo:
    apiUrl = "https://i2gnet.gtcomnet.com";
    break;
  case Envs.production:
    apiUrl = "https://api.inspired2go.org";
    break;
}

const baseUrl = `${apiUrl}/api/admin/v1/`;
//
const url = (path) => baseUrl + path;
const apiUrls = {
  upload: url("upload"),
  export: url("export"),
  login: url("login"),
  profile: url("profile"),
  analysis: url("analysis"),
  devotionAnalysis: url("devotion-analysis"),
  comments: url("comments"),
  devotion: url("devotion"),
  word: url("devotion/wod"),
  plans: url("plans"),
  categories: url("categories"),
  createCategories: url("categories/create"),
  updateCategories: url("categories/update"),
  deleteCategories: url("categories/delete"),
  user: url("user"),
  updates: url("updates"),
  subscription: url("subscription"),
  donations: url("donations"),
};

const version = "1.0.6";

export { baseUrl, apiUrls, isDevelopment, version };
