<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>User List</h4>
            <h6>Manage Your Users</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <div class="dropdown">
              <a
                href="javascript:void(0);"
                class="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Export Data
              </a>
              <ul class="dropdown-menu">
                <li class="mx-0">
                  <span
                    class="border-0 dropdown-item"
                    @click="store.exportData('users', 'pdf', currentFilterText)"
                  >
                    PDF
                  </span>
                </li>
                <li>
                  <span
                    class="border-0 dropdown-item"
                    @click="store.exportData('users', 'csv', currentFilterText)"
                    >CSV</span
                  >
                </li>
              </ul>
            </div>
          </li>
         
          <li>
            <a title="Refresh" @click="userStore.fetchUsers()"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn d-flex gap-2">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('Create')"
          >
            <vue-feather type="plus-circle" class="me-2"></vue-feather>
            Add New User
          </a>
          <!-- <a style="border-color: var(--PRIMARY-COLOR) !important;color: var(--PRIMARY-COLOR) !important;"
            href="javascript:void(0);" class="btn btn-added bg-white" @click="openModal('ADD_SUBSCRIPTION')">
            <vue-feather type="plus-circle" class="me-2"></vue-feather>
            Create Subscription
          </a> -->
        </div>
      </div>
      <!-- USER TABLE -->
      <filter-table
        :filter-by="filterBy"
        :extra-class="['table', 'datanew']"
        :columns="userAnalysis.columns"
        :data="userAnalysis.users"
        :filters="userAnalysis.filters"
        v-model:page-data="pageData"
        v-model:current-filter-text="currentFilterText"
      >
        <template #header>
          <h4>All Users</h4>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <div class="userimgname gap-2 align-items-center">
              <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                <Avatar
                  :src="record.profile_picture"
                  :alt="record.name"
                  img-class="user-image"
                />
              </a>
              <div>
                <a href="javascript:void(0);">{{ record.name }}</a>
              </div>
            </div>
          </template>
          <template v-else-if="column.key === 'created_by'">
            <span>{{ record.admin?.name || "--" }}</span>
          </template>
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateUserStatus($event, record.id)"
            />
          </template>
          <template v-else-if="column.key === 'subscription'">
            <span :class="getBadgeClass(record.subscription.is_active)">{{
              record.subscription_name
            }}</span>
          </template>
          <template v-else-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a
                  class="me-2 p-2 mb-0"
                  href="javascript:void(0);"
                  @click="openModal('View', record.id)"
                >
                  <vue-feather type="eye" class="action-eye"></vue-feather>
                </a>
                <a
                  class="me-2 p-2 mb-0"
                  @click="openModal('Update', record.id)"
                >
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                  @click="deleteUser(record.id)"
                  ><i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <UsersListModal
    :action="userModalAction"
    :user-id="userAnalysis.selectedUserId"
    ref="userModal"
  />
  <!-- <UserDetailsModal :user-id="userAnalysis.selectedUserId" v-if="showUserDetailsModal" /> -->
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useUserStore } from "@/store/userStore";
import { toggleCollapse } from "@/utils/components";
import Avatar from "@/components/avatar.vue";
import UsersListModal from "./modals/users-list-modal.vue";
import { confirm } from "@/utils/swal";
import { useMainStore } from "@/store/mainStore";
// store
const store = useMainStore();
const userStore = useUserStore();

// data
const userAnalysis = ref({
  filters: ["name", "email"],
  columns: ref([
    {
      title: "S/N",
      key: "serial",
      dataIndex: "index",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Phone",
      dataIndex: "mobile_number",
      sorter: {
        compare: (a, b) => {
          a = (a.mobile_number || "").toLowerCase();
          b = (b.mobile_number || "").toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => {
          a = a.email.toLowerCase();
          b = b.email.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: {
        compare: (a, b) => {
          a = (a.country || "").toLowerCase();
          b = (b.country || "").toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Subscription",
      key: "subscription",
      sorter: {
        compare: (a, b) => {
          a = a.subscription_name.toLowerCase();
          b = b.subscription_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created By",
      key: "created_by",
      sorter: {
        compare: (a, b) => {
          a = (a.admin?.name || "").toLowerCase();
          b = (b.admin?.name || "").toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ]),
  users: computed(() => userStore.users),
  selectedUserId: ref(null),
});

// refs
const currentFilterText = ref();
const pageData = ref({ page: 1, pageSize: null });
const filterBy = ref([
  { title: "Active Users", key: "is_active", value: true },
  { title: "Inactive Users", key: "is_active", value: false },
  { title: "Users With Donation", key: "has_donation", value: true },
]);
const collapseHeader = ref(null);
const showUserModal = ref(false);
const showUserDetailsModal = ref(false);
const userModal = ref(null);
const userModalAction = ref(null);

// methods
function openModal(action, userId = null) {
  userAnalysis.value.selectedUserId = userId;
  userModalAction.value = action;
  userModal.value.openModal();
}

function getBadgeClass(status) {
  return `badge badge-line${status ? "success" : "danger"}`;
}

async function deleteUser(userId) {
  confirm({
    title: "Are you sure about this?",
    message: "Note that all the data related to this user will be deleted!",
    callback: async (confirmed) => {
      if (confirmed) {
        await userStore.deleteUser(userId);
      }
    },
  });
}
async function updateUserStatus(isActive, userId) {
  await userStore.updateUser(userId, {
    status: isActive ? "active" : "inactive",
  });
}
onMounted(async () => {
  if (userAnalysis.value.users.length == 0) {
    await userStore.fetchUsers();
  }
});
</script>
