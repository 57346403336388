import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import { processUser, useUserStore } from "./userStore";

export const useSubscriptionStore = defineStore("subscription", {
  state: () => {
    return {
      subscriptions: () => {
        return useUserStore().users.filter((user) => !!user.subscription.name);
      },
    };
  },
  getters: {
    // subscriptions() {
    //   useUserStore().users.filter(
    //     (user) => user.subscription.is_active == true
    //   );
    // },
  },
  actions: {
    async updateSubscription(subscription = { status, plan_id, user_id }) {
      try {
        const response = await api.patch(
          `${apiUrls.subscription}/${subscription.user_id}`,
          subscription
        );

        // update the use to skip fetching all user again
        const updatedUserIndex = useUserStore().users.findIndex(
          (user) => user.id == subscription.user_id
        );

        // update the user subscription details
        let user = useUserStore().users[updatedUserIndex];
        user.subscription = response.data.data;
        useUserStore().users[updatedUserIndex] = processUser(user);
        Swal.fire(`Subscription Successfully Updated`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
    async createSubscription(subscription = { plan_id, user_id }) {
      try {
        const response = await api.post(
          `${apiUrls.subscription}`,
          subscription
        );

        // update the use to skip fetching all user again
        const updatedUserIndex = useUserStore().users.findIndex(
          (user) => user.id == subscription.user_id
        );

        // update the user subscription details
        let user = useUserStore().users[updatedUserIndex];
        user.subscription = response.data.data;
        useUserStore().users[updatedUserIndex] = processUser(user);
        Swal.fire(`Subscription Successfully Created`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
  },
});
