import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

export const useDashboardStore = defineStore("dashboard", {
  state: () => {
    return {};
  },
  actions: {
    async fetchAnalysis() {
      try {
        const response = await api.get(apiUrls.analysis);
        return response.data.data;
      } catch (error) {}
    },
    async fetchDevotionAnalysis(params) {
      try {
        const response = await api.get(apiUrls.devotionAnalysis, { params });
        return response.data.data;
      } catch (error) {}
    },
  },
});
