<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Donation List</h4>
            <h6>Manage Donations</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
          <li>
            <a title="Refresh" @click="fetchDonations()"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
        </ul>
        <div class="page-btn"></div>
      </div>
      <!-- USER TABLE -->
      <filter-table
        :filter-by="filterBy"
        :extra-class="['table', 'datanew']"
        :columns="donations.columns"
        :data="donations.data"
        :filters="donations.filters"
        v-model:page-data="pageData"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'user'">
            <div class="userimgname gap-2 align-items-center">
              <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                <Avatar
                  :src="record.user.profile_picture"
                  :alt="record.user.name"
                  img-class="user-image"
                />
              </a>
              <div>
                <a href="javascript:void(0);">{{ record.user.name }}</a>
              </div>
            </div>
          </template>
          <template v-else-if="column.key === 'amount'">
            {{ formatAmount(record.amount, true) }}
          </template>
          <template v-else-if="column.key === 'status'">
            <span :class="getBadgeClass(record.status)">{{
              record.status
            }}</span>
          </template>

          <template v-else-if="column.key === 'created_at'">
            {{ formatDate(record.created_at) }}
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, reactive } from "vue";
import { toggleCollapse } from "@/utils/components";
import Avatar from "@/components/avatar.vue";
import { useDonationStore } from "@/store/donationStore";
import { formatAmount, formatDate } from "@/utils";
// store
const donationStore = useDonationStore();

// data
const filterBy = ref([
  { title: "Pending", key: "status", value: "Pending" },
  { title: "Completed", key: "status", value: "Completed" },
]);

const donations = reactive({
  filters: ["name"],
  columns: [
    {
      title: "S/N",
      key: "serial",
      dataIndex: "index",
    },
    {
      title: "User",
      key: "user",
      sorter: {
        compare: (a, b) => {
          a = a.user.name.toLowerCase();
          b = b.user.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Amount",
      key: "amount",
      sorter: {
        compare: (a, b) => {
          a = a.amount.toLowerCase();
          b = b.amount.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created At",
      key: "created_at",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ],
  data: computed(() =>
    donationStore.donations.map((donation) => ({
      ...donation,
      name: donation.user.name,
    }))
  ),
});

const pageData = ref({ page: 1, pageSize: null });
// ref elements
const collapseHeader = ref(null);
// Methods

function getBadgeClass(status) {
  return `badge badge-line${status == "Completed" ? "success" : "grey"}`;
}
function fetchDonations() {
  donationStore.fetchDonations();
}
onMounted(async () => {
  if (donations.data.length <= 0) {
    fetchDonations();
  }
});
</script>
