<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>
              Devotionals for {{ getMonthName(devotionSort.selectedMonth) }}
            </h4>
            <h6>Manage daily devotionals</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="toggleDatePicker()"
          >
            <vue-feather type="plus-circle" class="me-2"></vue-feather>
            {{ isAboutAddNew ? "Cancel" : "Add New Devotional" }}
          </a>
        </div>
        <div class="page-btn" v-if="isAboutAddNew">
          <a-date-picker
            open
            :style="{ height: '38px', width: '171px' }"
            @change="addNewDevotion($event.$d)"
          />
        </div>
      </div>
      <!-- /product list -->
      <div class="card">
        <div class="card-body pb-0">
          <div class="table-top table-top-new">
            <div class="search-set mb-0">
              <!-- <div class="total-employees">
                <h6>
                  <i data-feather="users" class="feather-user"></i>Total Employees
                  <span>21</span>
                </h6>
              </div> -->
              <div class="search-input">
                <a href="" class="btn btn-searchset"
                  ><i data-feather="search" class="feather-search"></i
                ></a>
                <input type="search" class="form-control" v-model="filter" />
              </div>
            </div>
            <div
              class="search-path d-flex align-items-center search-path-new gap-2"
            >
              <div class="form-sort">
                <vue-feather type="sliders" class="info-img"></vue-feather>
                <vue-select
                  v-model="devotionSort.selectedYear"
                  :options="devotionSort.years"
                  id="sort2"
                  placeholder="Select Year"
                  @select="yearSelected()"
                />
              </div>
              <div class="form-sort">
                <vue-feather type="sliders" class="info-img"></vue-feather>
                <div class="tooltip" id="yearTooltip"></div>
                <vue-select
                  v-model="devotionSort.selectedMonth"
                  :options="devotionSort.months"
                  id="monthSelect"
                  placeholder="Select Month"
                  @select="loadMonthDevotions()"
                  ref="monthSelect"
                >
                </vue-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /product list -->

      <div class="employee-grid-widget">
        <div></div>
        <a-list
          v-if="showList"
          :pagination="pagination"
          :grid="{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 5 }"
          :data-source="monthDevotion"
        >
          <template #renderItem="{ item }">
            <DevotionCard
              @devotion-copied="(devotion) => copyDevotion(devotion)"
              :devotion="item.devotion"
              :date="item.date"
              :type="devotionTypes.dailyDevotion"
            />
          </template>
        </a-list>
        <div v-else>
          <h4 class="text-center">{{ listMessage }}</h4>
        </div>
      </div>
    </div>
  </div>
  <AddDevotionModal />
  <div class="copy-step">
    <i class="icon-arrow-right"></i>
  </div>
</template>
<script setup>
import { generateNumberList, getMonthName } from "@/utils";
import { computed, onMounted, reactive, ref, watch } from "vue";
import DevotionCard from "./components/devotion-card.vue";
import { useDevotionStore } from "@/store/devotionStore";
import { toggleCollapse } from "@/utils/components";
import AddDevotionModal from "./modal/add-devotion-modal.vue";
import { useRouter } from "vue-router";
import { devotionTypes } from "@/constants/enum";
import { encodeUrlData, years } from "./utils/data";

// hooks
const router = useRouter();
// STOREs
const devotionStore = useDevotionStore();

// HTML REFs
const collapseHeader = ref(null);
const monthSelect = ref(null);

// DATA
const isAboutAddNew = ref(false);
const todayDate = new Date();
const showList = ref(true);
const filter = ref("");
const copiedDevotion = ref(null);
/** Message to show the user when the list is hidden */
const listMessage = ref(null);
const pagination = {
  onChange: (page) => {},
  pageSize: 12,
};

/** All Data for sorting the Devotional */
const devotionSort = reactive({
  selectedYear: ref(todayDate.getFullYear()),
  selectedMonth: ref(todayDate.getMonth()),
  years: years(),
  months: generateNumberList(0, 11).map((number) => ({
    text: getMonthName(number),
    id: Number.parseInt(number),
  })),
});
/** Devotional for the selected Year and Month */
const monthDevotion = computed(() => {
  let devotions = [
    ...devotionStore.loadDevotionsByMonth(
      devotionSort.selectedYear,
      devotionSort.selectedMonth
    ),
  ];
  if (!!filter.value) {
    devotions = devotions.filter((devotion) =>
      (devotion.devotion?.title ?? "")
        .toLowerCase()
        .includes(filter.value.toLowerCase())
    );
  }
  return devotions;
});

function toggleDatePicker() {
  isAboutAddNew.value = !isAboutAddNew.value;
}

function copyDevotion(devotion) {
  copiedDevotion.value = devotion;
  isAboutAddNew.value = true;
}
function addNewDevotion(pickedDate) {
  const date = pickedDate.toISOString().slice(0, 10);
  const encodedData = encodeUrlData(devotionTypes.dailyDevotion, date);
  router.push({
    name: "add-edit-devotion",
    params: {
      action: "add",
      encodedData: encodedData,
    },
  });
}
/** Load the Devotional for the selected Month */
async function loadMonthDevotions() {
  showList.value = true;

  const query = {
    year: devotionSort.selectedYear,
    month: devotionSort.selectedMonth,
  };
  await devotionStore.fetchDevotionByMonth(query);
}

/** Inform the User to Select a Month */
function yearSelected() {
  showList.value = false;
  devotionSort.selectedMonth = null;
  listMessage.value = "Select a Month to Fetch the Devotionals";
}
onMounted(() => {
  if (monthDevotion.value.length == 0) {
    loadMonthDevotions();
  }
});
</script>

<style>
.ant-pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}
</style>
