<template>
  <textarea :id="id"></textarea>
</template>
<script setup>
import { useMainStore } from "@/store/mainStore";
import { fileUploadTargets } from "@/utils/file";
import { onMounted, ref, watch } from "vue";

const store = useMainStore();
const props = defineProps({
  id: String,
  content: String,
  placeholder: String,
  initialContent: String,
});

const emit = defineEmits(["update:content"]);

function loadEditor() {
  const summernote = $(`#${props.id}`).summernote({
    height: 500, // Increased height for better editing space
    minHeight: 300,
    maxHeight: null,
    focus: true,
    placeholder: props.placeholder,
    toolbar: [
      ["style", ["style"]],
      [
        "font",
        [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "superscript",
          "subscript",
          "clear",
        ],
      ],
      ["fontname", ["fontname"]],
      ["fontsize", ["fontsize"]],
      ["color", ["color"]],
      ["para", ["ul", "ol", "paragraph", "height"]],
      ["table", ["table"]],
      ["insert", ["link", "picture", "video", "hr"]],
      ["view", ["undo", "redo", "fullscreen", "codeview", "help"]],
    ],
    callbacks: {
      onInit: function () {
        // Custom initialization code here
      },
      onImageUpload: async function (files, editor, welEditable) {
        console.log(files);
        const response = await store.uploadFile(
          files[0],
          fileUploadTargets.ATTACHMENT
        );
        $(`#${props.id}`).summernote("insertImage", response.url);
      },
      onMediaDelete: function (target, editor, $editable) {
        store.deleteFile(target[0].src);
      },
    },
  });

  // Event handler for change in content
  $(summernote).on("summernote.change", function () {
    // Get updated content
    const content = $(this).summernote("code");
    emit("update:content", content);
  });
}
watch(
  () => props.content,
  (newValue, oldValue) => {
    // if it's first load
    if (!oldValue) {
      $(`#${props.id}`).summernote("code", props.content);
    }
  }
);
onMounted(() => {
  loadEditor();
});
</script>
<style>
@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("@/assets/fonts/summernote/summernote.eot?#iefix")
      format("embedded-opentype"),
    url("@/assets/fonts/summernote/summernote.woff2") format("woff2"),
    url("@/assets/fonts/summernote/summernote.woff") format("woff"),
    url("@/assets/fonts/summernote/summernote.ttf") format("truetype");
}
</style>
